@import "./_mantine.scss";
.logoWrapper {
  width: 82px;
  height: 82px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 55px;
    height: 55px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
