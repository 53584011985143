@import "./_mantine.scss";
.fullWidthSectionWhite {
  background-color: white;
}
.fullWidthSectionGray {
  background-color: #f6f6f6;
  padding-bottom: var(--mantine-spacing-lg);
}
.container {
  width: 75%;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
    padding: 0 20px 0;
  }
}
.headingImage {
  width: 100%;
  margin: var(--mantine-spacing-md) 0;

  @media (min-width: $mantine-breakpoint-xs) {
    max-height: 246px;
    overflow: hidden;
  }
}
.heading {
  color: var(--mantine-color-blue-8);
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0;
  @media (max-width: $mantine-breakpoint-xs) {
    font-size: 24px;
    line-height: 24px;
  }
}
.subheading {
  color: var(--mantine-color-blue-6);
  font-size: var(--mantine-font-size-sm);
  font-weight: 600;
  margin-top: 0;
  cursor: pointer;
  text-decoration: underline;
}
.sectionHeading {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 24px;
}
.divider {
  background-color: whitesmoke;
  height: 10px;
  border: none;
  outline: none;
}
.section {
  margin: 0 0 40px 0;
}
.viewActivitiesButton {
  background-color: var(--mantine-color-pink-6);
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: var(--mantine-spacing-xl);
  max-width: 426px;
  a {
    text-decoration: none;
    color: white;
  }
  &:hover {
    background-color: var(--mantine-other-pinkHover);
  }
  @media (max-width: $mantine-breakpoint-xs) {
    width: 100%;
  }
}
.grid {
  margin-bottom: var(--mantine-spacing-sm);
  @media (max-width: $mantine-breakpoint-xs) {
    margin-bottom: 0;
  }
}
.mapWrapper {
  width: 100%;
  height: 206px;
  margin-bottom: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-sm);
  overflow: hidden;
  @media (max-width: $mantine-breakpoint-xs) {
    height: 170px;
  }
}
.imageWrapper {
  border-radius: var(--mantine-radius-sm);
  overflow: hidden;
  max-height: 522px;
  img {
    width: 100%;
  }
  @media (max-width: $mantine-breakpoint-sm) {
    border-radius: 0;
    margin: 0 -20px;
  }
}
.mobileLogoSectionWrapper {
  position: relative;
  margin-bottom: var(--mantine-spacing-xl);
}
.onlineBadge {
  background: white;
  color: var(--mantine-color-gray-6);
  border-radius: 4px;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  height: auto;
}

.spoilerControl {
  color: var(--mantine-color-blue-6);
  font-weight: 600;
  font-size: var(--mantine-font-size-xs);
  line-height: 16px;
  padding-top: var(--mantine-spacing-xs);
  &:hover {
    text-decoration: underline;
  }
}
.spoilerGradient {
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 100%;
    background: linear-gradient(transparent, white);
  }
}
.sectionContent {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-md);
  line-height: 22px;
  margin: 0;
  //styles for embedded HTML rendered directly from rich text editor
  p {
    margin-block: 0;
  }
  ol {
    margin-block: 0;
  }
  ul {
    margin-block: 0;
  }
  li {
    margin-top: 0.25rem;
    p {
      margin-block: 0;
    }
  }
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-sm);
    line-height: 18px;
  }
}
.iconsContainer {
  display: flex;
  flex-direction: row;
  gap: var(--mantine-spacing-xl);
  @media (max-width: $mantine-breakpoint-sm) {
    flex-direction: column;
    gap: var(--mantine-spacing-sm);
    align-items: start;
  }
}
.sectionHeader {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  margin-top: var(--mantine-spacing-md);
  margin-bottom: 0;
  //TODO check border radius prop
  border-radius: 0;
  -moz-border-radius: 0;
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-lg);
  }
}
.headerWrapper {
  margin: 0 0 40px 0;
  padding: 10px 20px;
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0 20px 16px 20px;
    margin: 0 -20px 0 -20px;
    width: 100vw;
    border-bottom-right-radius: var(--mantine-radius-md);
    border-bottom-left-radius: var(--mantine-radius-md);
    box-shadow: 0 6px 6px #eaebef;
  }
}
.accordionSectionTitle {
  width: 75%;
  margin: 0 auto;
  padding-left: 20px;
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}
.desktopHeaderWrapper {
  @media (min-width: $mantine-breakpoint-sm) {
    border-bottom-right-radius: var(--mantine-radius-md);
    border-bottom-left-radius: var(--mantine-radius-md);
    box-shadow: 0 6px 6px #eaebef;
  }
}

.filterTags {
  background-color: var(--mantine-color-gray-6);
  color: white;
  display: inline-block;
  margin-right: var(--mantine-spacing-xs);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

.filterTagWrapper {
  padding-left: 20px;
  margin-bottom: 24px;
  @media (min-width: $mantine-breakpoint-sm) {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 24px;
  }
}
