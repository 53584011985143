@import "./_mantine.scss";
.header {
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  @media (max-width: 280px) {
    font-size: var(--mantine-font-size-xs);
    line-height: 16px;
  }
}
.middleSection {
  text-align: center;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-md);
  font-weight: 700;
  margin: 4px 0;
  @media (max-width: 280px) {
    font-size: var(--mantine-font-size-xs);
  }
}
.bottomSection {
  color: var(--mantine-color-gray-6);
  font-size: var(--mantine-font-size-sm);
  line-height: 18px;
  @media (max-width: 280px) {
    font-size: var(--mantine-font-size-xs);
    line-height: 16px;
  }
}
.gridColumn {
  @media (max-width: $mantine-breakpoint-sm) {
    padding: 0;
    width: 94px;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: calc(100% - 65px);
    height: calc(100% - 30px);
    right: 0px;
    width: 1px;
    background: var(--mantine-color-gray-2);
    @media (min-width: $mantine-breakpoint-sm) {
      top: calc(100% - 80px);
    }
    @media (max-width: 280px) {
      display: none;
    }
  }
}
.applyRightBorder {
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: calc(100% - 60px);
    height: 40px;
    right: 0;
    width: 1px;
    background: var(--mantine-color-gray-2);
    @media (min-width: $mantine-breakpoint-sm) {
      top: calc(100% - 70px);
    }
    @media (max-width: 280px) {
      display: none;
    }
  }
}
.grid {
  padding-bottom: var(--mantine-spacing-xs);
  border-bottom: 1px solid var(--mantine-color-gray-2);
  margin: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    border-bottom: none;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.logoWithoutReviews {
  display: flex;
  justify-content: center;
  div: {
    width: fit-content;
    height: fit-content;
  }
}
