@import "./_mantine.scss";
.bannerContainer {
  @media (max-width: $mantine-breakpoint-sm) {
    padding-bottom: 130px;
  }
  padding-bottom: 80px;
}

.bannerWrapper {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;
  }
  padding: 24px 40px;
  margin-top: 80px;
  background-image: url('/pebble_promise_background.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.supplierBanner {
  margin-top: 32px;
  margin-bottom: 48px;
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: calc(2 * var(--mantine-spacing-sm));
    margin-bottom: var(--mantine-spacing-xl);
    margin-left: 0;
    margin-right: 0;
  }
}

.list {
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: 6px;
    padding-left: 20px;
    margin-bottom: 0;
  }
  padding-left: 20px;
  margin: 0;
}

.listItem {
  &::marker {
    color: var(--mantine-color-pink-5);
    font-size: 1rem;
  }
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--mantine-color-blue-8);
  margin-bottom: 10px;
}

.heading {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: 18px;
  margin-bottom: var(--mantine-spacing-md);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.control {
  font-size: 12px;
  color: var(--mantine-color-blue-6);
  font-weight: 700;
  text-decoration: underline;
}

.imageContainer {
  position: relative;
}

.promiseImage {
  position: absolute;
  width: auto;
  top: -15px;
  right: 15px;
  height: 64px;
  width: 70px;
  @media (max-width: $mantine-breakpoint-sm) {
    top: -20px;
    right: 25px;
  }
  @media (max-width: 338px) {
    display: none;
  }
}
